import React, { Component } from "react";
import { Image, Row, Col } from "react-bootstrap";
import {
  FaBible,
  FaChurch,
  FaUserGraduate,
  FaUsers,
  FaUniversalAccess,
  FaCross,
} from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

class About extends Component {
  render() {
    const BigScreen = ({ children }) => {
      const isDesktop = useMediaQuery({ minWidth: 768 });
      return isDesktop ? children : null;
    };
    const SmallScreen = ({ children }) => {
      const isDesktop = useMediaQuery({ maxWidth: 767 });
      return isDesktop ? children : null;
    };
    return (
      <div>
        <Image width="100%" src="/Pictures/Vision.png" />
        <Image width="100%" src="/Pictures/Theme.png" />
        <br />
        <hr />
        <h2>Our Distinctives</h2>
        <BigScreen>
          <Row>
            <Col>
              <FaBible size="50px" />
              <h5>EVANGELICAL</h5>
              <p style={{ textAlign: "justify" }}>
                JCUSCF is committed and based upon the entirety of the good news
                of Jesus Christ as revealed in Scripture.
              </p>
            </Col>
            <Col>
              <FaChurch size="50px" />
              <h5>CHURCH-ORIENTED</h5>
              <p style={{ textAlign: "justify" }}>
                JCUSCF believes that as part of the universal Church, we have
                been strategically placed to be witnesses for Christ on campus.
                We support churches by training and equipping CF members in
                their schooling years to be effective Salt and Light after
                graduation.
              </p>
            </Col>
            <Col>
              <FaUserGraduate size="50px" />
              <h5>
                STUDENT-LED AND <br />
                STUDENT-INITIATED
              </h5>
              <p style={{ textAlign: "justify" }}>
                JCUSCF is a student ministry which believes in student
                initiative, responsibility and leadership as the core structure
                of developing our members.
              </p>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FaUsers size="50px" />
              <h5>INTERDENOMINATIONAL</h5>
              <p style={{ textAlign: "justify" }}>
                JCUSCF celebrates the diversity that different denominations and
                churches bring to enrich our walk with God. This is done on the
                basis of a subscription to the doctrinal basis of the Fellowship
                and their commitment to the aims of the Fellowship.
              </p>
            </Col>
            <Col>
              <FaUniversalAccess size="50px" />
              <h5>FORMACION*</h5>
              <p style={{ textAlign: "justify" }}>
                JCUSCF is committed to the wholistic development of a person,
                working towards maturity and Christ-likeness.
              </p>
              <p style={{ textAlign: "left" }}>
                * Formación: growing deeper in the understanding and practice of
                the Lordship of Christ in all areas of life.
              </p>
            </Col>
            <Col>
              <FaCross size="50px" />
              <h5>WHOLISTIC WITNESS</h5>
              <p style={{ textAlign: "justify" }}>
                JCUSCF is committed to the good news of Jesus Christ by
                demonstrating the integration of faith and life. We communicate
                this creatively and clearly in word, in action, and through
                relationships – within and across cultures and geographical
                boundaries.
              </p>
            </Col>
          </Row>
        </BigScreen>
        <SmallScreen>
          <FaBible size="50px" />
          <h5>EVANGELICAL</h5>
          <p style={{ textAlign: "justify" }}>
            JCUSCF is committed and based upon the entirety of the good news of
            Jesus Christ as revealed in Scripture.
          </p>
          <FaChurch size="50px" />
          <h5>CHURCH-ORIENTED</h5>
          <p style={{ textAlign: "justify" }}>
            JCUSCF believes that as part of the universal Church, we have been
            strategically placed to be witnesses for Christ on campus. We
            support churches by training and equipping CF members in their
            schooling years to be effective Salt and Light after graduation.
          </p>
          <FaUserGraduate size="50px" />
          <h5>
            STUDENT-LED AND <br />
            STUDENT-INITIATED
          </h5>
          <p style={{ textAlign: "justify" }}>
            JCUSCF is a student ministry which believes in student initiative,
            responsibility and leadership as the core structure of developing
            our members.
          </p>
          <FaUsers size="50px" />
          <h5>INTERDENOMINATIONAL</h5>
          <p style={{ textAlign: "justify" }}>
            JCUSCF celebrates the diversity that different denominations and
            churches bring to enrich our walk with God. This is done on the
            basis of a subscription to the doctrinal basis of the Fellowship and
            their commitment to the aims of the Fellowship.
          </p>
          <FaUniversalAccess size="50px" />
          <h5>FORMACION*</h5>
          <p style={{ textAlign: "justify" }}>
            JCUSCF is committed to the wholistic development of a person,
            working towards maturity and Christ-likeness.
          </p>
          <p style={{ textAlign: "left" }}>
            * Formación: growing deeper in the understanding and practice of the
            Lordship of Christ in all areas of life.
          </p>
          <FaCross size="50px" />
          <h5>WHOLISTIC WITNESS</h5>
          <p style={{ textAlign: "justify" }}>
            JCUSCF is committed to the good news of Jesus Christ by
            demonstrating the integration of faith and life. We communicate this
            creatively and clearly in word, in action, and through relationships
            – within and across cultures and geographical boundaries.
          </p>
        </SmallScreen>
      </div>
    );
  }
}

export default About;
