import React, { Component } from "react";
import { Image } from "react-bootstrap";
class Home extends Component {
  render() {
    return (
      <div className="Content">
        <h2>JCUS Christian Fellowship</h2>
        <br />
        <Image width="60%" src="./Pictures/Home.jpg"></Image>
        <br />
        <br />
        <div style={{ textAlign: "left" }}>
          <h3>Hello there!</h3>
          <br />
          <p>
            <font size="+2">
              We are JCUSCF! We are a Christian student-led ministry in James
              Cook University (Singapore). We have a variety of events and
              activities stretched throughout the trimester. Through our weekly
              sessions, we explore the Christian faith and its teaching
              together. We aim to be the Salt and Light on campus and to better
              serve our school community. All are welcome to join us!!
            </font>
          </p>
        </div>
      </div>
    );
  }
}

export default Home;
