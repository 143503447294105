import React, { Component } from "react";
import { FaRegSmileBeam } from "react-icons/fa";
import { Image } from "react-bootstrap";

class Contact extends Component {
  render() {
    return (
      <div style={{ textAlign: "left" }}>
        <h3>
          Hello! Would you like to find out more about JCUSCF? Do contact us.
        </h3>
        <br />
        <h4>When do we meet?</h4>
        <p>
          We meet every Thursday for either Small Groups or Speaker sessions.
          For this semester, we will be meeting on Zoom.
        </p>
        <h3>
          <u>Contact details</u>
        </h3>
        <p>
          <Image
            width="25px"
            src="/Pictures/instagram.png"
            style={{ paddingRight: "5px" }}
          />
          <a href="https://www.instagram.com/jcuscf/">@jcuscf</a> (DM us{" "}
          <FaRegSmileBeam />)
        </p>
        <p>
          <Image
            width="20px"
            src="/Pictures/facebook.png"
            style={{ marginRight: "5px", borderRadius: "5px" }}
          />
          <a href="https://www.facebook.com/jcuscf">
            JCUS Christian Fellowship
          </a>
        </p>
        <p>
          <Image
            width="20px"
            src="/Pictures/gmail.png"
            style={{ marginRight: "5px", borderRadius: "5px" }}
          />
          <a href="mailto:jcuschristianfellowship@gmail.com">
            jcuschristianfellowship@gmail.com
          </a>
        </p>
      </div>
    );
  }
}

export default Contact;
