import React, { Component } from "react";
import { Container, Nav, Navbar, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Home from "./Components/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Progs from "./Components/Progs";
import Contact from "./Components/Contact";
import About from "./Components/About";
import Stories from "./Components/Stories";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
      content: <Home />,
    };
  }
  render() {
    const BigScreen = ({ children }) => {
      const isDesktop = useMediaQuery({ minWidth: 768 });
      return isDesktop ? children : null;
    };
    const SmallScreen = ({ children }) => {
      const isDesktop = useMediaQuery({ maxWidth: 767 });
      return isDesktop ? children : null;
    };
    const handleChange = (key) => {
      switch (key) {
        case "1":
          this.setState({
            activeKey: "1",
            content: <Home />,
          });
          break;

        case "2":
          this.setState({
            activeKey: "2",
            content: <About />,
          });
          break;

        case "3":
          this.setState({
            activeKey: "3",
            content: <Progs />,
          });
          break;

        case "4":
          this.setState({
            activeKey: "4",
            content: <Stories />,
          });
          break;

        case "5":
          this.setState({
            activeKey: "3",
            content: <Contact />,
          });
          break;

        default:
          break;
      }
    };
    return (
      <div className="App">
        <Container className="Container">
          <Navbar>
            <Image
              roundedCircle
              width="15%"
              src="./android-chrome-512x512.png"
            />
            <BigScreen>
              <Nav
                fill
                defaultActiveKey={this.state.activeKey}
                onSelect={handleChange}
                style={{ paddingLeft: "50px", fontSize: "24px" }}
              >
                <Nav.Item>
                  <Nav.Link eventKey="1">Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2">About Us</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="3">Programs & Events</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="4">Our Stories</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="5">Contact us</Nav.Link>
                </Nav.Item>
              </Nav>
            </BigScreen>
            <SmallScreen>
              <Nav
                fill
                defaultActiveKey={this.state.activeKey}
                onSelect={handleChange}
                style={{ paddingLeft: "25px" }}
              >
                <div>
                  <Nav.Item>
                    <Nav.Link eventKey="1">Home</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2">About Us</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3">Programs & Events</Nav.Link>
                  </Nav.Item>
                </div>
                <br />
                <div>
                  <Nav.Item>
                    <Nav.Link eventKey="4">Our Stories</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="5">Contact us</Nav.Link>
                  </Nav.Item>
                </div>
              </Nav>
            </SmallScreen>
          </Navbar>
          {this.state.content}
        </Container>
      </div>
    );
  }
}

export default App;
