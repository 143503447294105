import React, { Component } from "react";
import ReactPlayer from "react-player";
import { Row, Col, Card } from "react-bootstrap";

class Stories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 1,
    };
  }
  render() {
    const click = (selected) => {
      this.setState({ selected });
    };
    const videos = {
      1: "https://www.facebook.com/jcuscf/videos/300017314481947/",
      2: "https://www.facebook.com/jcuscf/videos/2692101734400885/",
      3: "https://www.facebook.com/jcuscf/videos/303375367447644/",
    };
    const ronak = (
      <div
        style={{
          textAlign: "justify",
          width: "80%",
          left: "10%",
          position: "relative",
        }}
      >
        <h5>
          Ronak Adonai Robert, Bachelor of Psychological Science, Graduated SP51
          2019
        </h5>
        <p>
          When it first became clear I would be studying in JCU, one of the
          first things my father did, unbeknownst to me was to follow the JCUSCF
          Facebook page (go like the Facebook page!), and contact the admin to
          find out about the CF here. I guess he wanted me to find a Christian
          community in the school and be with the “good eggs”. Well, I don’t
          think he had to worry, I had a sense of conviction as well that I
          should join CF when I entered university.
        </p>
        <p>
          A lot of my life I felt like I was coasting by as a Christian, almost
          like a secret identity. Truth be told, I was worried I’d throw my
          identity to the wayside and wanted to join the fellowship to dedicate
          my years in JCU to God. In joining CF, I think my father got his wish
          of me being with some very “good eggs” of seniors and friends who were
          caring and always there to help. But more than just staying within
          ourselves, being in CF meant engaging the campus beyond us.
        </p>
        <p>
          Being a very public face of CF, I found that I was sometimes put in
          positions of being a spokesperson of our faith. To be able to fulfil
          this role of being the Salt and Light, I realised I had to engage with
          Scripture more, understanding how what I learnt in the academic world
          and in the Bible did not necessarily oppose each other but really
          helped me expand my knowledge in both. Most importantly, being in CF
          gave me the opportunity to serve, to care for our fellow students in
          our campus and even the community beyond us.
        </p>
        <p>
          I’ve been blessed to be placed in many positions which I truly feel
          that I was not qualified for, but being in CF gave me the opportunity
          to use these positions to demonstrate Christ’s love in the campus.
          Now, while I may not be as engaged with JCUSCF in particular, engaging
          with the wider student movement in my role with National Student
          Council(NSC), I am constantly reminded of God's presence in this
          ministry.
        </p>
        <p>
          Being in JCU can be quite hectic with the assignment schedule,
          all-year-round school term and little breaks in between. Sometimes it
          feels like a struggle balancing God in all of that, probably because
          that should not be the intention. Rather than balancing, we need to
          invite God into all aspects of these experiences, well, there will be
          a whole lot of difference.
        </p>
      </div>
    );
    const janine = (
      <div
        style={{
          textAlign: "justify",
          width: "80%",
          left: "10%",
          position: "relative",
        }}
      >
        <h5>
          Janine Tan, Bachelor of Psychological Science, Graduated SP52 2019
        </h5>
        <p>
          Most of my life in JCU was spent in CF, where I encountered many
          memorable moments that I hold close to my heart till now. I remember
          the first speaker session in my second semester was a sharing by
          Mercy, who was serving full-time at Tamar Village, a non-profit
          Christian organisation that reaches out to people in the red-light
          district. Mercy’s sharing largely included experiences in her life
          where she needed to have faith in God. Through her sharing, I could
          really tell how much she loves God, and how closely she was walking
          with Him, to the point that she could really let go and let God take
          complete control of her life. Mercy also shared that even if we walked
          away from God, He will still be right there behind us, so that once we
          turn back to Him, He is right there, near and close beside me. This
          reminded me of God’s faithfulness even in my unfaithfulness, which
          spurred me to persevere and walk closely with Him.
        </p>
        <p>
          Another memorable moment took place right before one of my midterms. I
          was doing some last-minute cramming in the school library, when a CF
          senior specially came over in the midst of her lesson to pray for me
          before I took the paper later. It was somewhat awkward, but a really
          nice gesture that made me appreciative of having Christian support in
          school.
        </p>
        <p>
          Also, I made many memorable moments during my first CF camp in 2018,
          one of which was when we played Captain’s Ball with a tissue packet.
          The tissue packet had burst open unknowingly, resulting in pieces of
          flying tissue whenever we threw the tissue packet, which was hilarious
          as we were super confused of the real location of the tissue packet.
          It was also through this camp that I found my close circle of CF
          friends whom I’m still close to.
        </p>
        <p>
          While I served as the Secretary in my final two semesters, I made even
          more memories while planning for different events. Despite the
          increasing workload, we also found ways to destress and have fun
          through fellowship and games, such as a board games party, which
          helped us grow closer as we got to spend more time together and
          outside of “work”.
        </p>
        <p>
          Looking back, I am thankful that God had led me to join CF in JCU.
          Joining CF has taught me many Biblical truths and principles that I
          was not previously exposed in church, such as the concept of social
          justice. I also got to see the Bible through different perspectives,
          due to the differing backgrounds of CFers and speakers. Furthermore, I
          was able to receive Christian support from CF friends who journeyed
          with me throughout JCU. Given JCU’s unique structure, there were many
          challenges that my church friends couldn’t fully understand. Thus,
          joining CF meant that I was able to gain Christian support from fellow
          students who could relate to what I was going through, and vice versa.
        </p>
        <p>
          In my opinion, the value in joining CF lies largely in being able to
          see the Bible through various and wider perspectives. In CF, you’ll
          get to interact with Christians from different family backgrounds,
          denominations, and nationalities. Through discussions and sharing
          sessions, you will get to listen to different viewpoints that you may
          not be exposed to in a church setting, making the session more
          fruitful. The value in joining CF is also the opportunity to forge
          bonds that are based on Christ. I thank God for providing CF as the
          platform for me to make friends with whom I can do all sort of things
          with, from having serious discussions and talks to making lame jokes
          and playing games.
        </p>
      </div>
    );
    const sherin = (
      <div
        style={{
          textAlign: "justify",
          width: "80%",
          left: "10%",
          position: "relative",
        }}
      >
        <h5>
          Sherin Sarah Varghese, Bachelor of Information Technology, Graduated
          SP53 2019
        </h5>
      </div>
    );
    const andersen = (
      <div
        style={{
          textAlign: "justify",
          width: "80%",
          left: "10%",
          position: "relative",
        }}
      >
        <h5>
          Andersen Stefanus Soeharto (Indonesian Ministry), Master of Business
          Administration, Graduated SP51 2020
        </h5>
        <p>
          There are two things that describe JCUSCF; Impacting and Transforming.
          I guess that’s how Christian students should behave. Have we been
          impacting and transforming the university life? You can get very high
          scores and be the top in the university, but when you fail to impact
          and transform the lives of others in the university, have you really
          succeeded?
        </p>
        <p>
          Through JCUSCF, I learnt how to be more impactful to the people around
          me. Not just about sharing the Gospel, but most importantly how we
          apply the Word of God in our university life, helping people and
          transforming their lives. God has given us talents and His blessings.
          Do we want to share them with others? That’s when we as Christians
          need a community to grow our faith and learn more about His Word. I
          believe that’s what JCUS CF stands for.
        </p>
        <p>
          I personally would like to encourage Christian students in JCU to join
          JCUSCF. It is a student-initiated ministry led by students to bless
          students with guidance from God. Let us spend our time to learn the
          Word of God and serve Him together in our university. Be Impactful and
          Transform people’s lives!
        </p>
      </div>
    );
    const selected = this.state.selected;
    return (
      <div>
        <h2>Graduates' Stories</h2>
        <Row style={{ width: "80%", left: "10%", position: "relative" }}>
          <Col>
            <Card
              bg={selected === 1 ? "primary" : "light"}
              text={selected === 1 ? "white" : "dark"}
              onClick={() => click(1)}
            >
              <Card.Img src="/Pictures/Ronak.png" />
              <Card.Title>Ronak</Card.Title>
            </Card>
          </Col>
          <Col>
            <Card
              bg={selected === 2 ? "primary" : "light"}
              text={selected === 2 ? "white" : "dark"}
              onClick={() => click(2)}
            >
              <Card.Img src="/Pictures/Janine.jpg" />
              <Card.Title>Janine</Card.Title>
            </Card>
          </Col>
          <Col>
            <Card
              bg={selected === 3 ? "primary" : "light"}
              text={selected === 3 ? "white" : "dark"}
              onClick={() => click(3)}
            >
              <Card.Img src="/Pictures/Sherin.jpg" />
              <Card.Title>Sherin</Card.Title>
            </Card>
          </Col>
          <Col>
            <Card
              bg={selected === 4 ? "primary" : "light"}
              text={selected === 4 ? "white" : "dark"}
              onClick={() => click(4)}
            >
              <Card.Img src="/Pictures/Andersen.png" />
              <Card.Title>Andersen</Card.Title>
            </Card>
          </Col>
        </Row>
        {selected !== 4 && <h4>Video Sharing</h4>}
        <ReactPlayer
          url={videos[selected]}
          controls
          style={{ marginLeft: "auto", marginRight: "auto" }}
          width="60%"
          height="60%"
        />
        {selected !== 3 && <h4>Written Testimony</h4>}
        {selected === 1
          ? ronak
          : selected === 2
          ? janine
          : selected === 3
          ? sherin
          : selected === 4
          ? andersen
          : ""}
      </div>
    );
  }
}

export default Stories;
