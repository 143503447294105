import React, { Component } from "react";
import { Image, Carousel, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

class Progs extends Component {
  render() {
    const BigScreen = ({ children }) => {
      const isDesktop = useMediaQuery({ minWidth: 768 });
      return isDesktop ? children : null;
    };
    const SmallScreen = ({ children }) => {
      const isDesktop = useMediaQuery({ maxWidth: 767 });
      return isDesktop ? children : null;
    };
    return (
      <div className="Content">
        <h2>Programs and Events</h2>
        <br />
        <div style={{ textAlign: "left" }}>
          <BigScreen>
            <Row>
              <Col>
                <h5>Fellowship Day</h5>

                <ul>
                  <li>First Thursday of every trimester</li>
                  <li>
                    It is an informal and chill session for CFers to catch up
                    and bond over games
                  </li>
                </ul>
              </Col>
              <Col>
                <h5>Welcome Day</h5>

                <ul>
                  <li>Second Thursday of every trimester</li>
                  <li>
                    JCUSCF’s Welcome Day is held to welcome and introduce new
                    members to CF activities, and to allow existing members to
                    connect with the new members
                  </li>
                </ul>
              </Col>
              <Col>
                <h5>Big Group</h5>

                <ul>
                  <li>Thursdays, 7:30pm on Zoom</li>
                  <li>
                    Big Group sessions are where all the CFers gather to
                    worship, pray, and learn more about God as students. Various
                    topics will be discussed in relation to the theme and our
                    roles in His Kingdom
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>Small Group</h5>

                <ul>
                  <li>Weekday evenings</li>
                  <li>
                    Small Group sessions are where CFers gather in groups to
                    study the Word and share their thoughts and perspectives in
                    a safe space
                  </li>
                </ul>
              </Col>
              <Col>
                <h5>Easter/Social Justice/Christmas</h5>

                <ul>
                  <li>
                    CF will organize each event during the trimester it
                    coincides with. It is for CFers to reach out to the school
                    community through the activities planned by the committees
                  </li>
                </ul>
              </Col>
              <Col>
                <h5>Bonding Day</h5>

                <ul>
                  <li>Mid-Trimester Break</li>
                  <li>
                    Bonding Day is set aside for members to take a breather
                    amidst the hectic school schedule and reconnect with each
                    other over a day of fun
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>Exam Blessing</h5>

                <ul>
                  <li>Second last Thursday of the trimester</li>
                  <li>
                    In line with our vision of being Salt and Light on campus,
                    CFers would distribute small care packages to the students
                    as a form of encouragement during the stressful final
                    submission and exam period
                  </li>
                </ul>
              </Col>
              <Col>
                <h5>What’s Next</h5>

                <ul>
                  <li>Last Thursday of the trimester</li>
                  <li>
                    With members graduating every trimester, What’s Next is a
                    special Big Group session which is set aside to focus on
                    post-graduation topics. It is also for us to thank and bless
                    our graduates for their contribution throughout their time
                    in JCUSCF
                  </li>
                </ul>
              </Col>
              <Col>
                <h5>CF Camp</h5>

                <ul>
                  <li>SP52 Holidays</li>
                  <li>
                    Camp helps to foster new friendships and strengthen old ones
                    through the activities planned. In line with the theme,
                    speakers will be invited to share their perspectives with
                    the members and there will be opportunities for discussion
                  </li>
                </ul>
              </Col>
            </Row>
          </BigScreen>
          <SmallScreen>
            <h5>Fellowship Day</h5>

            <ul>
              <li>First Thursday of every trimester</li>
              <li>
                It is an informal and chill session for CFers to catch up and
                bond over games
              </li>
            </ul>

            <h5>Welcome Day</h5>

            <ul>
              <li>Second Thursday of every trimester</li>
              <li>
                JCUSCF’s Welcome Day is held to welcome and introduce new
                members to CF activities, and to allow existing members to
                connect with the new members
              </li>
            </ul>

            <h5>Big Group</h5>

            <ul>
              <li>Thursdays, 7:30pm on Zoom</li>
              <li>
                Big Group sessions are where all the CFers gather to worship,
                pray, and learn more about God as students. Various topics will
                be discussed in relation to the theme and our roles in His
                Kingdom
              </li>
            </ul>

            <h5>Small Group</h5>

            <ul>
              <li>Weekday evenings</li>
              <li>
                Small Group sessions are where CFers gather in groups to study
                the Word and share their thoughts and perspectives in a safe
                space
              </li>
            </ul>

            <h5>Easter/Social Justice/Christmas</h5>

            <ul>
              <li>
                CF will organize each event during the trimester it coincides
                with. It is for CFers to reach out to the school community
                through the activities planned by the committees
              </li>
            </ul>

            <h5>Bonding Day</h5>

            <ul>
              <li>Mid-Trimester Break</li>
              <li>
                Bonding Day is set aside for members to take a breather amidst
                the hectic school schedule and reconnect with each other over a
                day of fun
              </li>
            </ul>

            <h5>Exam Blessing</h5>

            <ul>
              <li>Second last Thursday of the trimester</li>
              <li>
                In line with our vision of being Salt and Light on campus, CFers
                would distribute small care packages to the students as a form
                of encouragement during the stressful final submission and exam
                period
              </li>
            </ul>

            <h5>What’s Next</h5>

            <ul>
              <li>Last Thursday of the trimester</li>
              <li>
                With members graduating every trimester, What’s Next is a
                special Big Group session which is set aside to focus on
                post-graduation topics. It is also for us to thank and bless our
                graduates for their contribution throughout their time in JCUSCF
              </li>
            </ul>

            <h5>CF Camp</h5>

            <ul>
              <li>SP52 Holidays</li>
              <li>
                Camp helps to foster new friendships and strengthen old ones
                through the activities planned. In line with the theme, speakers
                will be invited to share their perspectives with the members and
                there will be opportunities for discussion
              </li>
            </ul>
          </SmallScreen>
        </div>
        <hr />
        <h2>Picture Slideshow</h2>
        <BigScreen>
          <Carousel style={{ backgroundColor: "dimgray" }} width="80%">
            <Carousel.Item>
              <Image width="66.667%" src="/Pictures/1.jpeg" />
              <Carousel.Caption>
                <h5>
                  <span className="Caption">
                    CFers playing board games during Fellowship Day
                  </span>
                </h5>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="66.667%" src="/Pictures/2.jpg" />
              <Carousel.Caption>
                <h5>
                  <span className="Caption">
                    CFers expressing their reflections on Psalm 13
                  </span>
                  <br />
                  <span className="Caption">
                    through painting during a Big Group session
                  </span>
                </h5>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="88.889%" src="/Pictures/3.jpg" />
              <Carousel.Caption>
                <h5>
                  <span className="Caption">
                    Christmas committee at the JCUSCF booth during Christmas
                    Market
                  </span>
                </h5>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="66.667%" src="/Pictures/4.jpeg" />
              <Carousel.Caption>
                <h5>
                  <span className="Caption">
                    CFers ready to distribute cookies to JCUS students
                  </span>
                  <br />
                  <span className="Caption">
                    as part of blessing them with love during Christmas
                  </span>
                </h5>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="66.667%" src="/Pictures/5.jpeg" />
              <Carousel.Caption>
                <h5>
                  <span className="Caption">
                    CFers writing encouragements for one another as part of a
                    Big Group activity
                  </span>
                </h5>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="66.667%" src="/Pictures/6.jpeg" />
              <Carousel.Caption>
                <h5>
                  <span className="Caption">
                    CFers blessing JCUS students with exam blessing packs
                  </span>
                </h5>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="73.478%" src="/Pictures/7.jpeg" />
              <Carousel.Caption>
                <h5>
                  <span className="Caption">
                    JCUS students happy to receive exam blessing packs
                  </span>
                </h5>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="78.817%" src="/Pictures/8.png" />
              <Carousel.Caption>
                <h5>
                  <span className="Caption">
                    JCUSCF continuing our weekly CF sessions on Zoom, amidst
                    Covid-19
                  </span>
                </h5>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="71.689%" src="/Pictures/9.png" />
              <Carousel.Caption>
                <h5>
                  <span className="Caption">
                    Despite Covid-19, JCUSCF continuing our weekly CF sessions
                  </span>
                  <br />
                  <span className="Caption">
                    on Zoom with invited guest speakers
                  </span>
                </h5>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </BigScreen>
        <SmallScreen>
          <Carousel style={{ backgroundColor: "dimgray" }} width="80%">
            <Carousel.Item>
              <Image width="66.667%" src="/Pictures/1.jpeg" />
              <Carousel.Caption
                style={{ width: "66.667%", position: "relative" }}
              >
                <p>
                  <span className="Caption">
                    CFers playing board games during Fellowship Day
                  </span>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="66.667%" src="/Pictures/2.jpg" />
              <Carousel.Caption
                style={{ width: "66.667%", position: "relative" }}
              >
                <p>
                  <span className="Caption">
                    CFers expressing their reflections on Psalm 13
                  </span>
                  <br />
                  <span className="Caption">
                    through painting during a Big Group session
                  </span>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="88.889%" src="/Pictures/3.jpg" />
              <Carousel.Caption
                style={{ width: "88.889%", position: "relative", left: "6%" }}
              >
                <p>
                  <span className="Caption">
                    Christmas committee at the Christmas Market’s JCUSCF booth
                  </span>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="66.667%" src="/Pictures/4.jpeg" />
              <Carousel.Caption
                style={{ width: "66.667%", position: "relative" }}
              >
                <p>
                  <span className="Caption">
                    CFers ready to distribute cookies to JCUS students
                  </span>
                  <br />
                  <span className="Caption">
                    as part of blessing them with love during Christmas
                  </span>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="66.667%" src="/Pictures/5.jpeg" />
              <Carousel.Caption
                style={{ width: "66.667%", position: "relative" }}
              >
                <p>
                  <span className="Caption">
                    CFers writing encouragements for one another as part of a
                    Big Group activity
                  </span>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="66.667%" src="/Pictures/6.jpeg" />
              <Carousel.Caption
                style={{ width: "66.667%", position: "relative" }}
              >
                <p>
                  <span className="Caption">
                    CFers blessing JCUS students with exam blessing packs
                  </span>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="73.478%" src="/Pictures/7.jpeg" />
              <Carousel.Caption
                style={{ width: "73.478%", position: "relative" }}
              >
                <p>
                  <span className="Caption">
                    JCUS students happy to receive exam blessing packs
                  </span>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="78.817%" src="/Pictures/8.png" />
              <Carousel.Caption
                style={{ width: "78.817%", position: "relative" }}
              >
                <p>
                  <span className="Caption">
                    JCUSCF continuing our weekly CF sessions on Zoom, amidst
                    Covid-19
                  </span>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image width="71.689%" src="/Pictures/9.png" />
              <Carousel.Caption
                style={{ width: "71.689%", position: "relative" }}
              >
                <p>
                  <span className="Caption">
                    Despite Covid-19, JCUSCF continuing our weekly CF sessions
                  </span>
                  <br />
                  <span className="Caption">
                    on Zoom with invited guest speakers
                  </span>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </SmallScreen>
      </div>
    );
  }
}

export default Progs;
